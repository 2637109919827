.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

}

.App-link {
  color: #61dafb;
}

.invisible {
  visibility: hidden;
}
.visible {
  visibility: visible;
}

.guessed-letter {
    background-color: white;
  font-weight: bolder;
  color: #797c7f;
}
.guessed-letter.guessed {
  color: white;
}

.guessed-letter.incorrect {
  background-color: #797c7f;
}
.guessed-letter.correct {
  background-color: #c9b563;
}
.guessed-letter.correct-exact {
  background-color: #6daa6b;
}

.keyboard-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}
.keyboard-row:not(:last-child) {
    margin-bottom: 2px;

}

.keyboard-key {
    width: 9vw;
    height: 9vw;
  display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.9em;
    border: none;
}
.keyboard-key.entr {
  font-size: 0.6em;
}
.keyboard-key.del {
    font-size: 0.8em;
}

.key-incorrect {
  background-color: #797c7f;
    color: white;
}
.key-correct {
  background-color: #c9b563;
    color: white;
}
.key-correct-exact {
  background-color: #6daa6b;
    color: white;
}

.lose-msg, .win-msg {
    font-size: 2em;
    font-weight: bolder;
}
.lose-msg {
    color: indianred;
}
.win-msg {
  color: #6daa6b;
}

.start-button {
    background-color: #6daa6b;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.5em;
    cursor: pointer;
}

